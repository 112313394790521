import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function Occasions() {
  return (
    <main className="py-10 px-14">
      <Helmet>
        <title>Occasions | Listwisher</title>
        <meta name="description" content="Gift-giving occasions. Create wishlists for birthdays, Christmas, other holidays and more." />
        <meta name="keywords" content="occasions, holidays, gifts, presents, list, birthday, christmas" />
      </Helmet>
      <h1 className="mb-4">Occasions</h1>
      <nav className="text-lg flex flex-col">
        <Link to="/birthday" className="text-blue-700 no-underline hover:underline">Birthday</Link>
        <Link to="/christmas" className="text-blue-700 no-underline hover:underline">Christmas</Link>
        <Link to="/hanukkah" className="text-blue-700 no-underline hover:underline">Hanukkah</Link>
        <Link to="/new-year" className="text-blue-700 no-underline hover:underline">New Year's</Link>
        <Link to="/lunar-new-year" className="text-blue-700 no-underline hover:underline">Lunar New Year</Link>
        <Link to="/valentines-day" className="text-blue-700 no-underline hover:underline">Valentine's Day</Link>
        <Link to="/easter" className="text-blue-700 no-underline hover:underline">Easter</Link>
        <Link to="/mothers-day" className="text-blue-700 no-underline hover:underline">Mother's Day</Link>
        <Link to="/fathers-day" className="text-blue-700 no-underline hover:underline">Father's Day</Link>
        <Link to="/graduation" className="text-blue-700 no-underline hover:underline">Graduation</Link>
      </nav>
    </main>
  )
}
