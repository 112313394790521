import Header from './components/Header';
import {
  ScrollRestoration,
  Outlet,
  useLocation,
} from 'react-router-dom';
import styled from '@emotion/styled';
import { NewWishlistModal } from './components/NewWishlistModal';
import { MODALS, useModalStore } from './state/modals';
import { ConfirmationModal } from './components/ConfirmationModal';
import { EditItemModal } from './components/EditItemModal';
import { EditWishlistModal } from './components/EditWishlistModal';
import { CreateAccountPromptModal } from './components/CreateAccountPromptModal';
import Footer from './components/Footer';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

const Container = styled.div({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export default function Layout() {
  const location = useLocation()
  const modals = useModalStore((state: any) => state.modals)
  const closeAllModals = useModalStore((state: any) => state.closeAllModals)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={'https://listwisher.com' + location.pathname} />
      </Helmet>
      <ScrollRestoration />
      <Container>
        <NewWishlistModal visible={!!modals[MODALS.NEW_WISHLIST_MODAL]} onHide={closeAllModals} />
        <EditItemModal visible={!!modals[MODALS.EDIT_ITEM_MODAL]} {...modals[MODALS.EDIT_ITEM_MODAL]} onHide={closeAllModals} />
        <EditWishlistModal visible={!!modals[MODALS.EDIT_WISHLIST_MODAL]} {...modals[MODALS.EDIT_WISHLIST_MODAL]} onHide={closeAllModals} />
        <ConfirmationModal visible={!!modals[MODALS.CONFIRMATION_MODAL]} {...modals[MODALS.CONFIRMATION_MODAL]} onHide={closeAllModals} />
        <CreateAccountPromptModal visible={!!modals[MODALS.CREATE_ACCOUNT_PROMPT_MODAL]} {...modals[MODALS.CREATE_ACCOUNT_PROMPT_MODAL]} onHide={closeAllModals} />
        <Header />
        <Suspense>
          <Outlet />
        </Suspense>
      </Container>
      <Footer />
    </>
  );
}
