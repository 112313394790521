import { lazy } from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import { AuthGuard } from "./components/AuthGuard";
import Layout from "./Layout";
import Occasions from "./pages/Occasions";

const Landing = lazy(() => import('./pages/Landing'));
const WishlistPage = lazy(() => import('./pages/Wishlist'));
const AboutPage = lazy(() => import('./pages/About'));
const SignupPage = lazy(() => import('./pages/Signup'));
const LoginPage = lazy(() => import('./pages/Login'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const MyWishlistsPage = lazy(() => import('./pages/MyWishlists'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'));
const TermsPage = lazy(() => import('./pages/Terms'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const GiftGuides = lazy(() => import('./pages/gift-guides'));
const MothersDay = lazy(() => import('./pages/gift-guides/MothersDay'));
const ValentinesDayForHer = lazy(() => import('./pages/gift-guides/ValentinesDayForHer'));
const ValentinesDayForHim = lazy(() => import('./pages/gift-guides/ValentinesDayForHim'));

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/wishlists/:wishlistId",
        element: <WishlistPage />,
      },
      {
        path: "/signup",
        element: <SignupPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/mywishlists",
        element: <AuthGuard><MyWishlistsPage /></AuthGuard>,
      },
      {
        path: "/occasions",
        element: <Occasions />
      },
      {
        path: "/birthday",
        element: <Landing holiday="birthday" />,
      },
      {
        path: "/christmas",
        element: <Landing holiday="Christmas" color="#DC2626" />,
      },
      {
        path: "/hanukkah",
        element: <Landing holiday="Hanukkah" color="#2563EB" />,
      },
      {
        path: "/new-year",
        element: <Landing holiday="New Year's" color="#FACC15" />,
      },
      {
        path: "/lunar-new-year",
        element: <Landing holiday="Lunar New Year" color="#DC2626" />,
      },
      {
        path: "/valentines-day",
        element: <Landing holiday="Valentine's Day" color="#EC4899" />,
      },
      {
        path: "/easter",
        element: <Landing holiday="Easter" color="#22D3EE" />,
      },
      {
        path: "/mothers-day",
        element: <Landing holiday="Mother's Day" color="#A855F7" />,
      },
      {
        path: "/fathers-day",
        element: <Landing holiday="Father's Day" color="#3B82F6" />,
      },
      {
        path: "/graduation",
        element: <Landing holiday="graduation" color="#94A3B8" />,
      },
      // TODO: bridal shower
      // TODO: baby shower / baby registry
      // TODO: wedding registry
      {
        path: "/gift-guides",
        element: <GiftGuides />,
      },
      {
        path: "/gift-guides/mothers-day",
        element: <MothersDay />
      },
      {
        path: "/gift-guides/valentines-day-for-her",
        element: <ValentinesDayForHer />
      },
      {
        path: "/gift-guides/valentines-day-for-him",
        element: <ValentinesDayForHim />
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicyPage />
      },
      {
        path: "/terms",
        element: <TermsPage />
      },
      {
        path: "/contact",
        element: <ContactPage />
      },
      {
        path: "/*",
        element: <NotFoundPage />,
      },
    ]
  }
]);
